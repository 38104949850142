import axios from 'axios';
// import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import Link from 'next/link';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { apiConstants } from '../../constants/apiConstants';
import {
  BodyContainer,
  ContentContainer,
  ContentMenu,
  ContentMenuItem,
  ContentWrapper,
  NoResultsFound,
  CollectionListItem,
  CollectionListWrapper,
} from '../../styles/pageStyles/collectionListStyle';
import { getUserInfoSSR } from '../../utils/cf';
import { useRouter } from 'next/router';
import { useCallback,  useEffect, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { WIDTH } from '../../constants/screenResolution';

interface CollectionData {
  [key: string]: Array<{ [key: string]: string }>;
}

interface PageProps {
  data: CollectionData;
}
const alphabet = Array.from(Array(26))
  .map((e, i) => String.fromCharCode(i + 65))
  .concat('#');

const CollectionListPage = (props: PageProps) => {
  const [newAlphabet, setNewAlphabet] = useState<string[]>(alphabet);

  const router = useRouter();
  const { width } = useWindowDimensions();

  const key = router.query.key as string;

  const scrollToCollections = useCallback(
    (id: string) => {
      if (!width) {
        return;
      }

      const systemReqEl = document.getElementById(id);

      if (systemReqEl) {
        const rect = systemReqEl.getBoundingClientRect();

        window.scroll(
          0,
          width <= getNumFromResolutionConstant(WIDTH.mobileMax)
            ? window.scrollY + rect.top - 79
            : window.scrollY + rect.top - 210
        );
      }
    },
    [width]
  );

  useEffect(() => {
    const collection = props.data;
    const result: string[] = [];
    alphabet.map((letter: string) => {
      const x = letter === '#' ? 'other' : letter;
      if (collection[x]?.length > 0) {
        result.push(letter);
      }
    });

    setNewAlphabet(result);
  }, [alphabet, props.data]);

  return (
    <>
      <Head>
        <title>Collection List - Driffle </title>
      </Head>
      <Header />
      <Navbar />
      <BodyContainer>
        <ContentWrapper>
          <ContentContainer>
            <ContentMenu>
              {newAlphabet.map((letter: string, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() =>
                      scrollToCollections(`collections-starting-with-${letter}`)
                    }
                  >
                    <ContentMenuItem
                      selected={
                        letter === '#'
                          ? router.query.key === 'other'
                          : key?.split('-')[key?.split('-').length - 1] ==
                            letter
                      }
                    >
                      {letter}
                    </ContentMenuItem>
                  </div>
                );
              })}
            </ContentMenu>
            {props.data ? (
              <CollectionListWrapper>
                {newAlphabet.map((alpha: any) => {
                  return (
                    <div
                      key={alpha}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <div id={`collections-starting-with-${alpha}`}>
                        {alpha}
                      </div>
                      <div>
                        {props.data[alpha === '#' ? 'other' : alpha]?.map(
                          (coll: any, index: number) => {
                            return (
                              <Link
                                passHref
                                key={index}
                                href={'/store/collection/' + coll.name}
                              >
                                <a>
                                  <CollectionListItem>
                                    {coll.displayName}
                                  </CollectionListItem>
                                </a>
                              </Link>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </CollectionListWrapper>
            ) : (
              <NoResultsFound>No results found</NoResultsFound>
            )}
          </ContentContainer>
        </ContentWrapper>
      </BodyContainer>
      <Footer />
    </>
  );
};
interface ListData {
  id: number;
  name: string;
  displayName: string;
  location: string;
  type: string;
}

export async function getServerSideProps(context: any) {
  let data: {
    [key: string]: Array<{ [key: string]: string }>;
  } | null = null;
  const userInfo = await getUserInfoSSR(context.req, context.res);

  try {
    const response = await axios.get(
      `${apiConstants.server}/public/collection-list`
    );

    if (response.status === 200) {
      data = response.data;
    } else {
      data = null;
    }
  } catch (error: any) {}

  if (data) {
    return {
      props: {
        reduxInitialState: userInfo,
        data,
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
}

export default CollectionListPage;
