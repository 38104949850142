import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const BodyContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  color: ${(props) => props.theme.palette.text.t1};
  width: 100%;
  min-height: 50vh;
  height: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  margin: auto;
  padding: 0px 16px;
  max-width: 1352px;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: row-reverse;
    height: auto;
    gap: 16px;
  }
`;
export const ContentMenu = styled.div`
  position: sticky;
  top: 120px;
  display: flex;
  padding: 12px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 16px;
  width: 100%;
  height: fit-content;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  box-shadow: ${(props) => props.theme.shadows.s2};
  @media (max-width: ${WIDTH.mobileMax}) {
    position: sticky;
    top: 80px;
    bottom: 0px;
    margin-top: -40px;
    flex-direction: column;
    flex-wrap: no-wrap;
    width: auto;
    justify-content: space-evenly;
    height: calc(100vh - 100px);
    padding: 6px;
  }
`;
interface ContentMenuItemI {
  selected: boolean;
}
export const ContentMenuItem = styled.div<ContentMenuItemI>`
  height: 48px;
  width: 48px;
  font: 1.25rem/1.5rem Onest-Bold;
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.t1};

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  background: ${(props) =>
    props.selected
      ? props.theme.palette.background.bg3
      : props.theme.palette.background.bg1};
  :hover {
    background: ${(props) => props.theme.palette.background.bg2};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 20px;
    width: 20px;
    font: 1rem/1rem Onest-Bold;
  }
`;

export const CollectionListWrapper = styled.div`
  margin: 32px 0;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 16px;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding-top: 16px;
  }
`;

export const CollectionListItem = styled.div`
  font: 1.125rem/1.25rem Onest-Medium;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  padding: 16px;
  height: fit-content;
  cursor: pointer;
  :hover {
    // color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.background.bg3};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 1rem/1.25rem Onest-Medium;
    padding: 8px;
  }
`;
export const NoResultsFound = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 4px auto;
  font: 1.125rem/1.25rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 0.875rem/1.125rem Onest-SemiBold;
  }
`;
