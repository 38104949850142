import { memo } from 'react';
import MobileHeader from '../MobileHeader/MobileHeader';
import { NavItem, NavItemsName } from './NavbarStyle';

import dynamic from 'next/dynamic';

const NavbarPopover = dynamic(() => import('../NavbarPopover/NavbarPopover'), {
  ssr: false,
});

interface INavButton {
  name: string;
}

const NavButton = (props: INavButton) => {
  return (
    <NavItem>
      <NavItemsName>{props.name}</NavItemsName>
      {/* <DownArrowContainer>
        <ArrowDownIconBlack />
      </DownArrowContainer> */}
    </NavItem>
  );
};
interface Props {
  type?: string;
}

const Navbar = (props: Props) => {
  return (
    <>
      <MobileHeader type={props.type ?? ''} />
    </>
  );
};

export default memo(Navbar);
