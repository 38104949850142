import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const NavbarContainer = styled.nav`
  width: 100%;
  height: 70px;
  /* position: relative; */
  display: flex;
  background-color: ${COLORS.white};
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  box-shadow: 0px 16px 24px #0000000b;
  top: 0;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const NavbarWrapper = styled.div`
  height: 100%;
  position: relative;
  margin: 0 24px;
  width: 100%;
  /* @media (min-width: 480px) {
    margin: 0 20px;
  }
  @media (min-width: 800px) {
    margin: 0 20px;
    width: 100%;
  }
  @media (min-width: 1200px) {
    max-width: 90%;
    width: 100%;
    margin: auto;
  } */
  @media (min-width: ${WIDTH.desktopMin}) {
    max-width: calc(90% + 32px);
    width: 100%;
    margin: auto;
  }

  display: flex;
  align-items: center;
  top: 0;
`;

export const NavbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  div {
    justify-content: flex-start;
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  div {
    justify-content: flex-end;
  }
`;

export const NavItem = styled.div`
  display: flex;
  margin-right: 15px;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024) {
    flex-direction: column;
  }
  cursor: pointer;
  transition: 0.3s ease-in-out;
`;

export const NavItemsName = styled.div`
  color: ${COLORS.blackPrimary};
  padding: 12px 16px;
  border-radius: 8px;
  font: 16px/16px Onest-Bold;
  &:hover {
    background: ${COLORS.greyLight3};
  }
`;

export const DownArrowContainer = styled.div`
  display: flex;
  /* height: 100%; */
  margin-top: 10px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

export const Dropdown = styled.div`
  z-index: 4;
  #dropdownContentWrapper {
    display: none;
    position: absolute;
  }
  #dropdownContent {
    background-color: ${COLORS.white};
    min-width: 160px;
    box-shadow: 0px 8px 32px #00000014;
    z-index: 1;
    border: 1px solid #e0e0e0;
    transition: opacity 2s ease-in;
    border-radius: 8px;
  }
  &:hover {
    #dropdownContentWrapper {
      padding-top: 16px;
      display: block;
      transition: opacity 2s ease-in;
    }
  }
`;
